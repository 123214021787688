import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const IngredientsFilter = (props) =>{
  const ingredients = useSelector(state => state.ingredients);
  const {selectedIngredients, onChange} = props;

  const handleChangeMultiple = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl >
      <InputLabel id="ingredients-filter-label">Ingredients</InputLabel>
      <Select
        labelId="ingredients-filter-label"
        id="ingredients-filter"
        multiple
        value={selectedIngredients}
        onChange={handleChangeMultiple}
        input={<Input id="ingredients-filter-chip" />}
        renderValue={(selected) => (
          <div >
            {selected.map((value) => (
              <Chip key={value}
              label={ingredients.records.filter(ing=>ing.id === value)[0].name}/>
            ))}
          </div>
        )}
      >
        {ingredients.records.sort((i1,i2)=>i1.name.localeCompare(i2.name)).map(ing => (
          <MenuItem key={ing.id} value={ing.id} >
            {ing.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default IngredientsFilter;
