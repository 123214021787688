import * as types from '../constants/ActionTypes';
import MealService from "../services/meal.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_MEALS_API_STATE,
  apiState
});

const receiveMeals = meals => ({
    type: types.RECEIVE_MEALS,
    meals
});

export const getAllMeals = () => dispatch => {
  dispatch(setAPIState("get"));
  return MealService.getMeals().then(
    response => {
      dispatch(receiveMeals(response.data));
    },
    handleAPIError(dispatch));
};

const saveMealAction = meal => ({
    type: types.MEAL_SAVE,
    meal
});

export const saveMeal = meal => dispatch => {
  dispatch(setAPIState("save"));
  return MealService.saveMeal(meal).then(
    response => {
      console.log(response);
      if (!meal.id){
        meal.id = response.data.id;
      }
      if (response.data.ratingId){
        const myRating = meal.meal_ratings.find(rat=>!rat.id);
        myRating.id = response.data.ratingId;
      }
      dispatch(saveMealAction(meal));
    },
    handleAPIError(dispatch));
}

const deleteMealAction = mealId => ({
    type: types.MEAL_DELETE,
    mealId
});

export const deleteMeal = mealId => dispatch => {
  dispatch(setAPIState("delete"));
  return MealService.deleteMeal(mealId).then(
    response => {
    dispatch(deleteMealAction(mealId))
  },
  handleAPIError(dispatch));
}

const setSelectedMealAction = row => ({
  type: types.SET_SELECTED_MEAL,
  row
})

export const setSelectedMeal = meal => dispatch => {
  dispatch(setSelectedMealAction(meal));
}
