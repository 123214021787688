import { isSameDay, format } from 'date-fns'

const renderDateLabel = date => {
  if (date && date!='Invalid Date'){
    if (isSameDay(date,new Date())){
      return "Today";
    }else{
      return format(date,"E do MMM");
    }
  }else{
    return "no date";
  }
};

export default renderDateLabel;
