import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'plan_template/';

class PlanTemplateService {

  getPlanTemplates() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  savePlanTemplate(template) {
    if (template.id){
      return axios.put(API_URL+template.id, template, { headers: authHeader() });
    } else {
      return axios.post(API_URL, template, { headers: authHeader() });
    }
  }

  deletePlanTemplate(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new PlanTemplateService();
