import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import FeaturePicker from '../common/FeaturePicker.component';
import IngredientsFilter from '../common/IngredientsFilter.component';
import MealRating from '../common/MealRating.component.js';
import PlannedMeal from './PlannedMeal.component';
import MealPhotoService from "../../services/meal_photo.service"
import { saveMealPhoto, deleteMealPhoto } from '../../actions/meal_photo.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    clear: 'right'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  galleryRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    //width: 500,
    //height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    overflow:'wrap',
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%, ' +
      'rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
}));

const MealSearch = (props) =>{
  const [selectedFeatures, setSelectedFeatures] = React.useState([]);
  const [selectedIngredients, setSelectedIngredients] = React.useState([]);
  const [nameFilter, setNameFilter] = React.useState("");
  const [mealTimeFilter, setMealTimeFilter] = React.useState(0);
  const [ratingFilter, setRatingFilter] = React.useState(1);
  const meals = useSelector(state => state.meal);
  const mealTimes = useSelector(state => state.mealTimes);
  const classes = useStyles();
  const {selected, onEditMealClick, gallery} = props;

  const changeNameFilter = (event) => {
    setNameFilter(event.target.value);
  };
  const featuresChange = (args) =>{
    setSelectedFeatures(args);
  };
  const changeMealTime = (event) => {
    setMealTimeFilter(event.target.value);
  };
  const ratingChanged = (val) => {
    setRatingFilter(val);
  };
  const pickMeal = meal => () => {
    props.pickMeal(meal)
  };
  const ingredientsChange = (args) =>{
    setSelectedIngredients(args);
  };

  const deletePhoto = () => {

  };

  const results = meals.records.filter(meal=>
    (nameFilter.length == 0 || meal.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0) &&
    (selectedFeatures.length == 0 || meal.meal_features.filter(feature => selectedFeatures.includes(feature)).length > 0) &&
    (mealTimeFilter == 0 ) &&
    (ratingFilter == 1 || meal.meal_ratings.filter(rating=>rating.rating >= ratingFilter).length > 0) &&
    (selectedIngredients.length == 0 || meal.ingredients.filter(ing => selectedIngredients.includes(ing.id)).length > 0)
  );

  return (
    <>
    <Accordion defaultExpanded={!selected}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{padding:2}}>
        <div style={{'verticalAlign':'middle'}}>
          {!gallery && <Typography className={classes.title}
            style={{
              'display':'inline-block',
              'marginRight':'5px'
            }}
            color="textSecondary" gutterBottom>
            {selected ? "Selected meal:" : "Pick a meal"}
          </Typography>}
          {selected ?
          <PlannedMeal plan={{mealId:selected.id}} showRating
            onClick={pickMeal(selected)} onEditMealClick={onEditMealClick} />
            : null}
        </div>
      </AccordionSummary>
      <AccordionDetails style={{padding:2}}>
        <Grid container spacing={1} >
          <Grid item xs={12} md={gallery?12:6}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Filters
                </Typography>
                <FormControl fullWidth={true}>
                  <TextField id="name-filter" label="Name" value={nameFilter} onChange={changeNameFilter} />
                </FormControl>
                <FormControl fullWidth={true}>
                  <FeaturePicker selectedFeatures={selectedFeatures} onChange={featuresChange} />
                </FormControl>
                <FormControl fullWidth={true}>
                  <InputLabel id="mealtime-label">Meal Time</InputLabel>
                  <Select
                    labelId="mealtime-label"
                    id="mealtime"
                    value={mealTimeFilter}
                    onChange={changeMealTime}
                  >
                  <MenuItem value={0}>All</MenuItem>
                  {/*mealTimes.records.map(time=>{
                    return (<MenuItem value={time.id}>{time.name}</MenuItem>);
                  })*/}
                  </Select>
                </FormControl>
                <MealRating name="rating-filter" value={ratingFilter} onChange={ratingChanged} />
                <FormControl fullWidth={true}>
                  <IngredientsFilter selectedIngredients={selectedIngredients} onChange={ingredientsChange} />
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
          {!gallery && <Grid item xs={12} md={6}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Search Results ({results.length} of {meals.records.length})
                </Typography>
                {results.length < 150 ?
                  results.map(meal=>{
                    return (
                      <div key={"m"+meal.id} style={{'marginBottom':'6px'}}>
                        <PlannedMeal plan={{mealId:meal.id}}
                          showRating showEdit
                          onClick={pickMeal(meal)} onEditMealClick={onEditMealClick} />
                      </div>
                    );
                  })
                : null}
              </CardContent>
            </Card>
          </Grid>}
        </Grid>
      </AccordionDetails>
    </Accordion>
    {gallery &&
      <div className={classes.galleryRoot}>
        <GridList spacing={1} className={classes.gridList}>
        {results.map(meal=>{
          const photo = meal.meal_photos.length > 0 ? meal.meal_photos[0] : {};
          return <>
          <GridListTile key={photo.id} >
            <a href={MealPhotoService.getMealPhotoUrl(photo.filename)+".jpg"} target="blank">
            <img src={MealPhotoService.getMealPhotoUrl(photo.filename)+"thumb.jpg"} width={300}/></a>
            <GridListTileBar
              title={meal.name}
              titlePosition="bottom"
              subtitle={meal.recipeURL.startsWith('http')?
                <a href={meal.recipeURL} className={classes.icon} target="blank">{meal.recipeURL}</a>:
                meal.recipeURL}
              actionIcon={
                <Tooltip key="edit-tip" title="Edit Meal">
                  <IconButton aria-label="edit meal" onClick={onEditMealClick(meal)}
                   className={classes.icon}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </GridListTile>
          </>
        })}</GridList>
      </div>
    }
    </>
  );
};

export default MealSearch;
