import { SET_MEALS_API_STATE, RECEIVE_MEALS, MEAL_SAVE, MEAL_DELETE, LOGOUT,
  MEAL_PHOTO_DELETE, MEAL_PHOTO_SAVE, SET_SELECTED_MEAL } from '../constants/ActionTypes'

const calcAvgRating = (meal) => {
  return (meal.meal_ratings.length > 0 ?
    meal.meal_ratings.reduce((accum,val)=>accum+val.rating,0)/meal.meal_ratings.length : 0);
};

const meals = (state = {records:[],apiState:"init",selectedRow:null}, action) => {
    switch (action.type) {
        case SET_SELECTED_MEAL:
            return {...state,selectedRow:action.row};
        case SET_MEALS_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_MEALS:
            return {
              records:
                action.meals.map(meal=>{
                  return {...meal,
                    avgRating: calcAvgRating(meal),
                    meal_features: meal.meal_features.map(f=>f.id)
                  };
                }),
                selectedRow:state.selectedRow,
                apiState:"ready"
              };
        case MEAL_DELETE:
            return {
              records:state.records.filter(meal => meal.id !== action.mealId),
              selectedRow:state.selectedRow,
              apiState:"ready"};
        case MEAL_SAVE: {
            var found = false;
            var result = state.records.map(meal => {
                if (meal.id === action.meal.id) {
                    found = true;
                    return {...action.meal, avgRating: calcAvgRating(action.meal)};
                }
                return meal;
            });
            if (!found){
                result = [...state.records, {...action.meal, avgRating: calcAvgRating(action.meal)}];
            }
            return {records:result, apiState:"ready",selectedRow:state.selectedRow};
          }
        case MEAL_PHOTO_DELETE: {
            let selectedRow = state.selectedRow;
            const result = {...state,
              records:state.records.map(meal => {
                if (meal.id !== action.photo.mealId){
                  return meal;
                }
                selectedRow = {...meal, meal_photos:meal.meal_photos.filter(photo=>photo.id !== action.photo.id)};
                return selectedRow;
            })};
            result.selectedRow = selectedRow;
            return result;
          }
        case MEAL_PHOTO_SAVE: {
            let selectedRow = state.selectedRow;
            const result = {...state,
              records:state.records.map(meal => {
                if (meal.id !== action.photo.mealId){
                  return meal;
                }
                selectedRow = {...meal,
                  meal_photos:meal.meal_photos.filter(photo=>photo.id !== action.photo.id)};
                selectedRow.meal_photos.push(action.photo);
                return selectedRow;
              })};
              result.selectedRow = selectedRow;
              return result;
            }
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
          }
    }

export default meals;
