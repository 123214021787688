import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'meal_photo/';
const IMG_URL = CommonServices.getHostUrl() + 'meal_photos/';

class MealPhotoService {
  getMealPhotoUrl(filename){
    return IMG_URL+filename;
  }

  saveMealPhoto(photo) {
    if (photo.id){
      return axios.put(API_URL+photo.id, photo, { headers: authHeader() });
    } else {
      const headers = {
        ...authHeader(),
        "Content-Type": "multipart/form-data"
      }
      let formData = new FormData();
      formData.append("file", photo.file);
      formData.append("title", photo.title);
      photo.description && formData.append("description", photo.description);
      formData.append("mealId", photo.mealId);
      photo.order && formData.append("order", photo.order);
      return axios.post(API_URL, formData, { headers });
    }
  }

  deleteMealPhoto(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new MealPhotoService();
