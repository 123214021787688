import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MealRating from './MealRating.component';
import renderDateLabel from '../../helpers/formatting';

const RatingsList = (props) =>{
  const auth = useSelector(state => state.auth);
  const {meal, onChange} = props;

  let myRating = meal.meal_ratings.find(rat=>rat.userId===auth.user.id);
  if (!myRating){
    myRating = {userId:auth.user.id, mealId:meal.id, comments:"", rating:3};
    meal.meal_ratings = [myRating,...meal.meal_ratings];
  } else {
    meal.meal_ratings = [myRating, ...meal.meal_ratings.filter(rat=>rat.userId!==auth.user.id)]
  }
  return (
    <>
      <h4>Average rating: <MealRating value={meal.avgRating} singleIcon={true} /></h4>

      {meal.meal_ratings.map(rat=>{
        return <Card key={"rating"+rat.id} variant="outlined">
          <CardContent>
            {rat.userId===auth.user.id?<>
              <h4>My Rating</h4>
              <MealRating key={"rating"+rat.id} name={"rating"+rat.id} value={rat.rating}
                onChange={(rating)=>onChange({...myRating, rating})} />
              <FormControl fullWidth={true}>
                <TextField id="comments" label="Comments" value={rat.comments}
                  onChange={(event)=>{onChange({...myRating, comments:event.target.value})}}/>
              </FormControl></>
            :<>
              <h4>Another Rating</h4>
              <MealRating value={rat.rating} singleIcon={true} /><br/>
              {rat.comments}<br/>
            </>}
            <small><i>Last updated: {renderDateLabel(new Date(rat.updatedAt))}</i></small>
          </CardContent>
        </Card>
      })
    }
    </>
  );
};

export default RatingsList;
