
const getHostUrl = () =>{
  if (process.env.NODE_ENV === "development") {
    return 'http://192.168.0.122:8080/';
  } else {
    return 'https://food.harleys.me/';
  }
};
const getApiUrl = () =>{
  return getHostUrl() + 'api/';
};
const CommonServices = {
  getHostUrl,
  getApiUrl
};

export default CommonServices;
