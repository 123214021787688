export const RECEIVE_MEAL_TIMES = 'RECEIVE_MEAL_TIMES';
export const SET_MEAL_TIMES_API_STATE = 'SET_MEAL_TIMES_API_STATE';

export const SET_MEAL_FEATURES_API_STATE = 'SET_MEAL_FEATURES_API_STATE';
export const RECEIVE_MEAL_FEATURES = 'RECEIVE_MEAL_FEATURES';

export const RECEIVE_MEALS = 'RECEIVE_MEALS';
export const MEAL_SAVE = 'MEAL_SAVE';
export const MEAL_DELETE = 'MEAL_DELETE';
export const SET_MEALS_API_STATE = 'SET_MEALS_API_STATE';
export const SET_SELECTED_MEAL = 'SET_SELECTED_MEAL';

export const RECEIVE_PLANNED_MEALS = 'RECEIVE_PLANNED_MEALS';
export const PLANNED_MEAL_SAVE = 'PLANNED_MEAL_SAVE';
export const PLANNED_MEAL_DELETE = 'PLANNED_MEAL_DELETE';
export const SET_PLANNED_MEALS_API_STATE = 'SET_PLANNED_MEALS_API_STATE';

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const RECEIVE_HOLIDAYS = 'RECEIVE_HOLIDAYS';
export const HOLIDAY_SAVE = 'HOLIDAY_SAVE';
export const HOLIDAY_DELETE = 'HOLIDAY_DELETE';
export const SET_HOLIDAYS_API_STATE = 'SET_HOLIDAYS_API_STATE';

export const SET_INGREDIENTS_API_STATE = 'SET_INGREDIENTS_API_STATE';
export const RECEIVE_INGREDIENTS = 'RECEIVE_INGREDIENTS';
export const INGREDIENT_SAVE = 'INGREDIENT_SAVE';
export const INGREDIENT_DELETE = 'INGREDIENT_DELETE';

export const MEAL_PHOTO_SAVE = 'MEAL_PHOTO_SAVE';
export const MEAL_PHOTO_DELETE = 'MEAL_PHOTO_DELETE';
export const SET_MEAL_PHOTOS_API_STATE = 'SET_MEAL_PHOTOS_API_STATE';

export const RECEIVE_PLAN_TEMPLATES = 'RECEIVE_PLAN_TEMPLATES';
export const PLAN_TEMPLATE_SAVE = 'PLAN_TEMPLATE_SAVE';
export const PLAN_TEMPLATE_DELETE = 'PLAN_TEMPLATE_DELETE';
export const SET_PLAN_TEMPLATES_API_STATE = 'SET_PLAN_TEMPLATES_API_STATE';
export const SET_SELECTED_PLAN_TEMPLATE = 'SET_SELECTED_PLAN_TEMPLATE';

export const RECEIVE_PLAN_TEMPLATE_ENTRIES = 'RECEIVE_PLAN_TEMPLATE_ENTRIES';
export const PLAN_TEMPLATE_ENTRY_SAVE = 'PLAN_TEMPLATE_ENTRY_SAVE';
export const PLAN_TEMPLATE_ENTRY_DELETE = 'PLAN_TEMPLATE_ENTRY_DELETE';
export const SET_PLAN_TEMPLATE_ENTRIES_API_STATE = 'SET_PLAN_TEMPLATE_ENTRIES_API_STATE';
