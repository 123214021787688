import * as types from '../constants/ActionTypes';
import PlanTemplateService from "../services/plan_template.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_PLAN_TEMPLATES_API_STATE,
  apiState
});

const receivePlanTemplates = templates => ({
    type: types.RECEIVE_PLAN_TEMPLATES,
    templates
});

export const getPlanTemplates = () => dispatch => {
  dispatch(setAPIState("get"));
  return PlanTemplateService.getPlanTemplates().then(
    response => {
      dispatch(receivePlanTemplates(response.data));
    },
    handleAPIError(dispatch));
};

const savePlanTemplateAction = template => ({
    type: types.PLAN_TEMPLATE_SAVE,
    template
});

export const savePlanTemplate = template => dispatch => {
  dispatch(setAPIState("save"));
  return PlanTemplateService.savePlanTemplate(template).then(
    response => {
      console.log(response);
      if (!template.id){
        template.id = response.data.id;
      }
      dispatch(savePlanTemplateAction(template));
    },
    handleAPIError(dispatch));
}

const deletePlanTemplateAction = templateId => ({
    type: types.PLAN_TEMPLATE_DELETE,
    templateId
});

export const deletePlanTemplate = templateId => dispatch => {
  dispatch(setAPIState("delete"));
  return PlanTemplateService.deletePlanTemplate(templateId).then(
    response => {
    dispatch(deletePlanTemplateAction(templateId))
  },
  handleAPIError(dispatch));
}

const setSelectedPlanTemplateAction = row => ({
  type: types.SET_SELECTED_PLAN_TEMPLATE,
  row
})

export const setSelectedPlanTemplate = row => dispatch => {
  dispatch(setSelectedPlanTemplateAction(row));
}
