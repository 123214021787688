import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'ingredient/';

class IngredientService {
  getIngredients() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  saveIngredient(ingredient) {
    if (ingredient.id){
      return axios.put(API_URL+ingredient.id, ingredient, { headers: authHeader() });
    } else {
      return axios.post(API_URL, ingredient, { headers: authHeader() });
    }
  }

  deleteIngredient(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new IngredientService();
