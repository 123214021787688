import * as types from '../constants/ActionTypes';
import PlannedMealService from "../services/planned_meal.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_PLANNED_MEALS_API_STATE,
  apiState
});

const receivePlannedMeals = meals => ({
    type: types.RECEIVE_PLANNED_MEALS,
    meals
});

export const getPlannedMeals = (from, to) => dispatch => {
  dispatch(setAPIState("get"));
  return PlannedMealService.getPlannedMeals(from, to).then(
    response => {
      dispatch(receivePlannedMeals(response.data));
    },
    handleAPIError(dispatch));
};

const savePlannedMealAction = meal => ({
    type: types.PLANNED_MEAL_SAVE,
    meal
});

export const savePlannedMeal = meal => dispatch => {
  dispatch(setAPIState("save"));
  return PlannedMealService.savePlannedMeal(meal).then(
    response => {
      console.log(response);
      if (!meal.id){
        meal.id = response.data.id;
      }
      dispatch(savePlannedMealAction(meal));
    },
    handleAPIError(dispatch));
}

const deletePlannedMealAction = mealId => ({
    type: types.PLANNED_MEAL_DELETE,
    mealId
});

export const deletePlannedMeal = mealId => dispatch => {
  dispatch(setAPIState("delete"));
  return PlannedMealService.deletePlannedMeal(mealId).then(
    response => {
    dispatch(deletePlannedMealAction(mealId))
  },
  handleAPIError(dispatch));
}
