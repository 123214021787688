import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getMealTimes } from '../../actions/meal_time.actions';
import { getAllMeals } from '../../actions/meal.actions';
import { getMealFeatures } from '../../actions/meal_feature.actions';
import { getHolidays } from '../../actions/holiday.actions';
import { getIngredients } from '../../actions/ingredient.actions';
import { getPlanTemplates } from '../../actions/plan_template.actions';
import { Redirect } from 'react-router-dom';

const StateManager = () => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const mealTimes = useSelector(state => state.mealTimes);
  const meal = useSelector(state => state.meal);
  const mealFeatures = useSelector(state => state.mealFeatures);
  const holidays = useSelector(state => state.holidays);
  const ingredients = useSelector(state => state.ingredients);
  const plan_templates = useSelector(state => state.plan_template);

  if (!auth.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const transition = () => {
    if (auth.isLoggedIn){
      if (mealTimes.apiState === "init"){
        dispatch(getMealTimes());
        return "Getting meal times";
      } else if (meal.apiState === "init"){
        dispatch(getAllMeals());
      } else if (mealFeatures.apiState === "init"){
        dispatch(getMealFeatures());
      } else if (holidays.apiState === "init"){
        dispatch(getHolidays());
      } else if (ingredients.apiState === "init"){
        dispatch(getIngredients());
      } else if (plan_templates.apiState === "init"){
        dispatch(getPlanTemplates());
      }
    }
  };

  return (<div style={{fontSize:"8pt", marginTop:20, color:'#aaa'}}>{transition()}</div>);
}

export default StateManager;
