import { useDispatch } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import { saveIngredient, deleteIngredient } from '../../actions/ingredient.actions';
import TextField from '@material-ui/core/TextField';

const IngredientForm = (props) =>{
  const {ingredient, onChange} = props;
  const dispatch = useDispatch();

  const deleteRecord = () => {
    dispatch(deleteIngredient(ingredient.id));
    close();
  };
  const saveRecord = () => {
    dispatch(saveIngredient(ingredient));
    close();
  };

  const handleDateChange = (date) => {
    onChange({...ingredient, date});
  };
  const close = () => {
    onChange(null)
  }

  if (!ingredient){
    return null;
  }
  return (
    <>
    <Dialog
        title="Ingredient"
        open={ingredient != null}
        scroll="paper"
        maxWidth="xl"
        onClose={close}
        >
      <DialogTitle id="form-dialog-title">Ingredient</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container >
          <Grid item>
            <FormControl fullWidth={true}>
              <TextField id="measure_unit" label="Measure Unit" value={ingredient.measure_unit}
                onChange={(event)=>onChange({...ingredient, measure_unit:event.target.value})} />
            </FormControl>
            <i>e.g. "100g" or "piece(s) of"</i><br/>
            <FormControl fullWidth={true}>
              <TextField id="name" label="Name" value={ingredient.name}
                onChange={(event)=>onChange({...ingredient, name:event.target.value})} />
            </FormControl>
            <i>Example appearance: "2 {ingredient.measure_unit} {ingredient.name}"</i><br/>
          </Grid>
          </Grid>
        </DialogContent>
      <DialogActions>
        <ButtonGroup  variant="contained" size="small" >
          <Button aria-label="save" color="primary" onClick={saveRecord}
            endIcon={<SaveIcon />}>Save</Button>
          <Button aria-label="delete" color="secondary" onClick={deleteRecord}
            endIcon={<DeleteIcon />}>Delete</Button>
          <Button aria-label="cancel" onClick={close}
            endIcon={<CancelIcon />}>Cancel</Button>
        </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IngredientForm;
