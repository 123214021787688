import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from '@material-ui/pickers';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { saveMeal, deleteMeal } from '../../actions/meal.actions';
import RatingsList from './RatingsList.component';
import IngredientsList from './IngredientsList.component';
import FeaturePicker from '../common/FeaturePicker.component';
import MealPhotoService from "../../services/meal_photo.service"
import { saveMealPhoto, deleteMealPhoto } from '../../actions/meal_photo.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    //width: 500,
    //height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
}));

const MealForm = (props) =>{
  const classes = useStyles();
  const {meal, onChange} = props;
  const dispatch = useDispatch();

  const deleteRecord = () => {
    dispatch(deleteMeal(meal.id));
    onClose();
  };
  const saveRecord = () => {
    dispatch(saveMeal(meal));
    onClose();
  };
  const onClose = () => {
    onChange(null);
  };

  const myRatingChanged = (rat) => {
    if (rat.id){
      onChange({...meal, meal_ratings: [rat, ...meal.meal_ratings.filter(r=>r.id!==rat.id)]});
    }else{
      onChange({...meal, meal_ratings: [rat,...meal.meal_ratings]});
    }
  };

  const ingredientsChanged = (ing) => {
    onChange(ing);
  };

  const selectFile = (ev) => {
    dispatch(saveMealPhoto({
      mealId:meal.id,
      file:ev.target.files[0],
      title:'',
      description:'',
      order:meal.meal_photos.length+1
    }));
  };
  const deletePhoto = (photo) => () => {
    dispatch(deleteMealPhoto(photo));
  }

  if (!meal){
    return null;
  }
  return (
    <>
    <Dialog
        title={"Meal "+meal?meal.name:""}
        open={meal != null}
        onClose={onClose}
        scroll="paper"
        maxWidth="xl"
        >
      <DialogTitle id="form-dialog-title">{"Meal "+(meal?meal.name:"")}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1} >
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Paper style={{padding:"3px"}}>
              <h3>Details</h3>
              <FormControl fullWidth={true}>
                <TextField id="name" label="Name" value={meal.name}
                  onChange={(event)=>onChange({...meal, name:event.target.value})} />
              </FormControl>
              <FormControl fullWidth={true}>
                <TextField id="recipeURL" label="Recipe URL" value={meal.recipeURL}
                  onChange={(event)=>onChange({...meal, recipeURL:event.target.value})} />
              </FormControl>
              <FormControl fullWidth={true}>
                <TextField id="prepTime" label="Preparation Time (mins)" value={meal.prepTime}
                  onChange={(event)=>onChange({...meal, prepTime:event.target.value})} />
              </FormControl>
              <FormControl fullWidth={true}>
                <TextField id="notes" label="Notes" value={meal.notes}
                  onChange={(event)=>onChange({...meal, notes:event.target.value})} />
              </FormControl>
              <FormControl fullWidth={true}>
                <FormControlLabel control={
                  <Switch id="advancedPrep" checked={meal.advancedPrep}
                    onChange={(event)=>onChange({...meal, advancedPrep:event.target.checked})} />
                  } label="Preparation required hours/days in advance" />
              </FormControl>
              <FormControl fullWidth={true}>
                <FeaturePicker selectedFeatures={meal.meal_features}
                  onChange={(meal_features)=>onChange({...meal, meal_features})} />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Paper style={{padding:"3px"}}>
              <h3>Ingredients (per portion)</h3>
              <IngredientsList meal={meal} onChange={ingredientsChanged}/>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Paper style={{padding:"3px"}}>
              <h3>Ratings</h3>
              <RatingsList meal={meal} onChange={myRatingChanged}/>
            </Paper>
          </Grid>
          <Grid item xs={12} >
            <Paper style={{padding:"3px"}}>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                   />
                <Button
                  className="btn-choose"
                  component="span"
                  endIcon={<AddAPhotoIcon />}>
                   Add photo
                </Button>
              </label><br/>
              <div className={classes.root}>
                <GridList cellWidth={200} spacing={1} className={classes.gridList}>
              {meal.meal_photos.map(photo=>{
                return <>
                <GridListTile key={photo.id}>
                  <a href={MealPhotoService.getMealPhotoUrl(photo.filename)+".jpg"} target="blank">
                  <img src={MealPhotoService.getMealPhotoUrl(photo.filename)+"thumb.jpg"} >
                  </img></a>
                  <GridListTileBar
                    title={photo.title}
                    titlePosition="top"
                    actionIcon={
                      <Button aria-label="delete" color="secondary" className={classes.icon}
                        onClick={deletePhoto(photo)}
                        endIcon={<DeleteIcon />}>Delete Photo</Button>
                    }
                    actionPosition="left"
                    className={classes.titleBar}
                  />
                </GridListTile>
                </>
              })}</GridList>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonGroup  variant="contained" size="small" >
          <Button aria-label="save" color="primary" onClick={saveRecord}
            endIcon={<SaveIcon />}>Save</Button>
          <Button aria-label="delete" color="secondary" onClick={deleteRecord}
            endIcon={<DeleteIcon />}>Delete</Button>
          <Button aria-label="cancel" onClick={onClose}
            endIcon={<CancelIcon />}>Cancel</Button>
        </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MealForm;
