import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';

const customIcons = {
  0: {
    icon: <ThumbsUpDownIcon />
  },
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const MealRating = (props) => {
  const changed = (event) => {
    props.onChange(parseInt(event.target.value));
  }
  return (<>
    {props.singleIcon?
    (props.value ? customIcons[props.value].icon : customIcons[0].icon):
      <Rating
          name={props.name}
          value={props.value}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          onChange={changed}
        />}</>);
}

export default MealRating;
