import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Link, useLocation } from 'react-router-dom';
import { logout } from "./actions/auth";
import clsx from 'clsx';
import './App.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
//import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Login from './components/login/login.component';
import Register from './components/login/register.component';
import Planner from './components/planner/PlannerPage.component';
import Holidays from './components/holidays/HolidaysPage.component';
import Settings from './components/settings/SettingsPage.component';
import MenuPage from './components/meals_menu/MenuPage.component';
import TodayIcon from '@material-ui/icons/Today';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import SettingsIcon from '@material-ui/icons/Settings';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import StateManager from './components/common/StateManager';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor:'#74653F',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 30,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const isKiosk = useSelector(state => state.auth.kiosk);
  const dispatch = useDispatch();
  //let query = useQuery();
  //const u = query.get("u");
  //const isKiosk = u && u.length > 0;

  const logOut = () => {
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //<MuiThemeProvider className="App">
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <CssBaseline />

        {!isKiosk?
        (<><AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
          <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
        <Typography variant="h6" noWrap>
          Food Planner
        </Typography>
      </Toolbar>
    </AppBar><Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        {isLoggedIn?
          (<><ListItemLink to="/planner" primary="Planner" icon={<TodayIcon />} />
          <ListItemLink to="/menu" primary="Menu" icon={<MenuBookIcon />} />
          <ListItemLink to="/holidays" primary="Holidays" icon={<BeachAccessIcon />} />
          <ListItemLink to="/settings" primary="Settings" icon={<SettingsIcon />} />
          <ListItemLink to="/login" primary="Log Out" icon={<MeetingRoomIcon />} onClick={logOut} /></>) :
          (<>
            <ListItemLink to="/login" primary="Log In" icon={<MeetingRoomIcon />} />
            <ListItemLink to="/register" primary="Register" icon={<PermIdentityIcon />} />
          </>)}
      </List>
    </Drawer></>):null}
    <main className={classes.content}>
      {!isKiosk?
      (<div className={classes.toolbar} />):null}
      <Route path="/" exact component={Planner} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/planner" component={Planner} />
      <Route path="/menu" component={MenuPage} />
      <Route path="/holidays" component={Holidays} />
      <Route path="/settings" component={Settings} />
    </main>
    <StateManager/>

    </div>
    </MuiPickersUtilsProvider>
  );
  //</MuiThemeProvider>
}

export default App;
