import { SET_PLAN_TEMPLATES_API_STATE, RECEIVE_PLAN_TEMPLATES, PLAN_TEMPLATE_SAVE,
  PLAN_TEMPLATE_DELETE, SET_SELECTED_PLAN_TEMPLATE,
  PLAN_TEMPLATE_ENTRY_SAVE, PLAN_TEMPLATE_ENTRY_DELETE, LOGOUT } from '../constants/ActionTypes'

const plan_template = (state = {records:[],apiState:"init",selectedRow:''}, action) => {
    switch (action.type) {
        case SET_SELECTED_PLAN_TEMPLATE:
            return {...state,selectedRow:action.row};
        case SET_PLAN_TEMPLATES_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_PLAN_TEMPLATES:
            return {...state,records:action.templates, apiState:"ready"};
        case PLAN_TEMPLATE_DELETE:
            return {...state,records:state.records.filter(row => row.id !== action.templateId), apiState:"ready"};
        case PLAN_TEMPLATE_SAVE:
            var found = false;
            var result = state.records.map(row => {
                if (row.id === action.template.id) {
                    found = true;
                    return action.template;
                }
                return row;
            });
            if (!found){
                result = [...state.records, action.template];
            }
            return {...state,records:result, apiState:"ready"};
        case PLAN_TEMPLATE_ENTRY_SAVE: {
            let selectedRow = state.selectedRow;
            const result = {...state,
              records:state.records.map(row => {
                if (row.id !== action.row.planTemplateId){
                  return row;
                }
                selectedRow = {...row,
                  plan_template_entries:row.plan_template_entries.filter(e=>e.id !== action.row.id)};
                selectedRow.plan_template_entries.push(action.row);
                return selectedRow;
              })};
              result.selectedRow = selectedRow;
              return result;
            }
        case PLAN_TEMPLATE_ENTRY_DELETE: {
            let selectedRow = state.selectedRow;
            const result = {...state,
              records:state.records.map(row => {
                if (row.id !== action.row.planTemplateId){
                  return row;
                }
                selectedRow = {...row,
                  plan_template_entries:row.plan_template_entries.filter(e=>e.id !== action.row.id)};
                return selectedRow;
            })};
            result.selectedRow = selectedRow;
            return result;
          }
        case LOGOUT:
          return {records:[], apiState:"init",selectedRow:null};
        default:
            return state;
          }
    }

export default plan_template;
