import { SET_MEAL_FEATURES_API_STATE, RECEIVE_MEAL_FEATURES } from '../constants/ActionTypes'

const mealFeatures = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_MEAL_FEATURES_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_MEAL_FEATURES:
            return {records:action.mealFeatures, apiState:"ready"};
        default:
            return state;
    }
}
export default mealFeatures;
