import * as types from '../constants/ActionTypes';
import PlanTemplateEntryService from "../services/plan_template_entry.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_PLAN_TEMPLATE_ENTRIES_API_STATE,
  apiState
});
/*
const receivePlanTemplateEntries = rows => ({
    type: types.RECEIVE_PLAN_TEMPLATE_ENTRIES,
    rows
});

export const getPlanTemplateEntries = (templateId) => dispatch => {
  dispatch(setAPIState("get"));
  return PlanTemplateEntryService.getPlanTemplateEntries(templateId).then(
    response => {
      dispatch(receivePlanTemplateEntries(response.data));
    },
    handleAPIError(dispatch));
};*/

const savePlanTemplateEntryAction = row => ({
    type: types.PLAN_TEMPLATE_ENTRY_SAVE,
    row
});

export const savePlanTemplateEntry = row => dispatch => {
  dispatch(setAPIState("save"));
  return PlanTemplateEntryService.savePlanTemplateEntry(row).then(
    response => {
      console.log(response);
      if (!row.id){
        row.id = response.data.id;
      }
      dispatch(savePlanTemplateEntryAction(row));
    },
    handleAPIError(dispatch));
}

const deletePlanTemplateEntryAction = row => ({
    type: types.PLAN_TEMPLATE_ENTRY_DELETE,
    row
});

export const deletePlanTemplateEntry = row => dispatch => {
  dispatch(setAPIState("delete"));
  return PlanTemplateEntryService.deletePlanTemplateEntry(row.id).then(
    response => {
    dispatch(deletePlanTemplateEntryAction(row))
  },
  handleAPIError(dispatch));
}
