import { useDispatch, useSelector } from "react-redux";
import MealSearch from './MealSearch.component';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import { savePlannedMeal, deletePlannedMeal } from '../../actions/planned_meal.actions';
import { savePlanTemplateEntry, deletePlanTemplateEntry } from '../../actions/plan_template_entry.actions';

const PlanForm = (props) =>{
  const meals = useSelector(state => state.meal);
  const mealTimes = useSelector(state => state.mealTimes);
  const {plan, onClose, onChange, onEditMealClick} = props;
  const dispatch = useDispatch();

  const deletePlan = () => {
    if (plan.planTemplateId){
      dispatch(deletePlanTemplateEntry(plan));
    } else {
      dispatch(deletePlannedMeal(plan.id));
    }
    onClose();
  };
  const savePlan = () => {
    if (plan.planTemplateId){
      dispatch(savePlanTemplateEntry(plan));
    } else {
      dispatch(savePlannedMeal(plan));
    }
    onClose();
  };

  const handleDateChange = (date) => {
    onChange({...plan, date});
  };

  const pickMeal = meal => {
    onChange({...plan, mealId:meal.id});
  };

  const handleChange = (event) => {
    onChange({...plan, mealTimeId:event.target.value});
  };

  if (!plan){
    return null;
  }
  const meal = meals.records.find(meal=>meal.id === plan.mealId);
  return (
    <>
    <Dialog
        title="Planed meal"
        open={plan != null}
        onClose={onClose}
        scroll="paper"
        maxWidth="xl"
        >
      <DialogTitle id="form-dialog-title">{`Planed${plan.planTemplateId?' template':''} meal`}</DialogTitle>
      <DialogContent dividers={true} style={{padding:'10px'}}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth={true}>
              <InputLabel id="mealtime-label">Meal Time</InputLabel>
              <Select
                labelId="mealtime-label"
                id="mealtime"
                value={plan.mealTimeId}
                onChange={handleChange}
              >
              {mealTimes.records.map(time=>{
                return (<MenuItem key={time.id} value={time.id}>{time.name}</MenuItem>);
              })}
              </Select>
            </FormControl>
            {!plan.planTemplateId&&<><DatePicker autoOk
              orientation="portrait"
              variant="static"
              openTo="date"
              value={plan.date} onChange={handleDateChange}
              xs={9}/>
            <FormControl >
              <TextField id="portions-made" label="Portions made"
                value={plan.portionsMade} type="number"
                onChange={(event)=>onChange({...plan, portionsMade:event.target.value})} />
            </FormControl>
            <FormControl >
              <TextField id="portions-consumed" label="Portions consumed"
                value={plan.portionsConsumed} type="number"
                onChange={(event)=>onChange({...plan, portionsConsumed:event.target.value})} />
            </FormControl></>}
            </Grid>
            <Grid item xs={12} md={8} style={{padding:0, paddingRight:4}}>
              <MealSearch selected={meal} pickMeal={pickMeal} onEditMealClick={onEditMealClick}/>
            </Grid>
          </Grid>
        </DialogContent>
      <DialogActions>
        <ButtonGroup  variant="contained" size="small" >
          <Button aria-label="save" color="primary" onClick={savePlan}
            endIcon={<SaveIcon />}>Save</Button>
          <Button aria-label="delete" color="secondary" onClick={deletePlan}
            endIcon={<DeleteIcon />}>Delete</Button>
          <Button aria-label="cancel" onClick={onClose}
            endIcon={<CancelIcon />}>Cancel</Button>
        </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlanForm;
