import { SET_PLANNED_MEALS_API_STATE, RECEIVE_PLANNED_MEALS, PLANNED_MEAL_SAVE, PLANNED_MEAL_DELETE, LOGOUT } from '../constants/ActionTypes'

const plannedMeals = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_PLANNED_MEALS_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_PLANNED_MEALS:
            return {records:action.meals.map(plan=>{
              return {
                ...plan,
                date: new Date(plan.date)
              }}), apiState:"ready"};
        case PLANNED_MEAL_DELETE:
            return {records:state.records.filter(meal => meal.id !== action.mealId), apiState:"ready"};
        case PLANNED_MEAL_SAVE:
            var found = false;
            var result = state.records.map(meal => {
                if (meal.id === action.meal.id) {
                    found = true;
                    return action.meal;
                }
                return meal;
            });
            if (!found){
                result = [...state.records, action.meal];
            }
            return {records:result, apiState:"ready"};
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
          }
    }

export default plannedMeals;
