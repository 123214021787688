import { SET_MEAL_TIMES_API_STATE, RECEIVE_MEAL_TIMES } from '../constants/ActionTypes'

const mealTimes = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_MEAL_TIMES_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_MEAL_TIMES:
            return {records:action.mealTimes, apiState:"ready"};
        default:
            return state;
    }
}
export default mealTimes;
