import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'planned_meal/';

class PlannedMealService {

  getPlannedMeals(from, to) {
    return axios.get(API_URL, { headers: authHeader(),
      params: {
         from,
         to}
       });
  }

  savePlannedMeal(meal) {
    if (meal.id){
      return axios.put(API_URL+meal.id, meal, { headers: authHeader() });
    } else {
      return axios.post(API_URL, meal, { headers: authHeader() });
    }
  }

  deletePlannedMeal(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new PlannedMealService();
