import React, { useRef, useEffect } from 'react';
import { useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import renderDateLabel from '../../helpers/formatting';

const ShoppingList = (props) => {
  const meals = useSelector(state => state.meal);
  const plannedMeals = useSelector(state => state.plannedMeals);
  //const ingredients = useSelector(state => state.ingredients);
  const [rows, setRows] = React.useState([]);
  const {open, onClose, fromDate, toDate} = props;
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (open){
      const newRows = [];
      plannedMeals.records.forEach(pm=>{
        const meal = meals.records.find(m=>m.id===pm.mealId);
        meal&&meal.ingredients.forEach(ing=>{
          let row = newRows.find(nr=>nr.id===ing.id);
          if (!row){
            row = {...ing, quantity:0, checked:false, plans:[]};
            newRows.push(row);
          }
          let portions = 1;
          let reason = 'assumed';
          if (pm.portionsMade){
            portions = pm.portionsMade;
            reason = 'made';
          } else if (pm.portionsConsumed){
            portions = pm.portionsConsumed;
            reason = 'consumed';
          }
          row.quantity = row.quantity + (ing.quantity*portions);
          row.plans.push({portions,meal,plan:pm,reason});
        });
      });
      if (rows){
        rows.forEach(oldRow=>{
          const newRow = newRows.find(nr=>nr.id===oldRow.id);
          if (newRow && newRow.quantity===oldRow.quantity){
            newRow.checked=oldRow.checked;
          }
        })
      }
      setRows(newRows);
    }
  },[open,fromDate,toDate,meals,plannedMeals]);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
  };

  const rowCheckOnChange = (row) => () => {
    const i = rows.indexOf(row);
    const newRows = [...rows];
    newRows.splice(i,1,{...row, checked:!row.checked});
    //newRows.push({...row, checked:!row.checked});
    setRows(newRows);
  };

  return (<>
    <Dialog
        title="Planed meal"
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth="xl"
        >
      <DialogTitle id="form-dialog-title">Shopping List</DialogTitle>
      <DialogContent dividers={true} style={{padding:'10px'}}>
        <TableContainer component={Paper}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell align="left">In basket</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Ingredient</TableCell>
                <TableCell align="left">What For</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row=>{
              return <TableRow hover key={"item"+row.id}>
                <TableCell>
                  <Checkbox
                    checked={row.checked}
                    onChange={rowCheckOnChange(row)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  {row.quantity + " " + (row.measure_unit?row.measure_unit:"")}
                </TableCell>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.plans.map(plan=>{
                    return (<>
                      {plan.meal.name} X {plan.portions} ({plan.reason}) on {renderDateLabel(plan.plan.date)}
                      <br/></>);
                  })}
                </TableCell>
              </TableRow>})}
            </TableBody>
          </Table>
        </TableContainer>
        <h3>Remaining</h3>
        <IconButton onClick={copyToClipboard}>
          <FontAwesomeIcon icon={faClipboard}/>
        </IconButton>
        <form>
          <textarea
            ref={textAreaRef}
            value={rows.filter(r=>!r.checked).map(row=>row.name).join('\n')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small" >
          <Button aria-label="cancel" onClick={onClose}
            endIcon={<CancelIcon />}>Close</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>);
};

export default ShoppingList;
