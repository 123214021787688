import React from 'react';
import { useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IngredientForm from './IngredientForm.component';

const IngredientsPage = () =>{
  const [ingredient, setIngredient] = React.useState(null);
  const ingredients = useSelector(state => state.ingredients);

  const handleClick = (event, ing) => {
    setIngredient(ing);
  };

  return (<>
    <IngredientForm ingredient={ingredient} onChange={setIngredient}/>
    <Button color="primary" variant="contained"
      onClick={(event) => handleClick(event, {})}>New Ingredient</Button>
    <TableContainer component={Paper} style={{'maxHeight':'400px'}}>
      <Table size="small" stickyHeader >
          <TableHead>
              <TableRow>
                <TableCell>Measure Unit</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {ingredients.records.sort((h1,h2)=>h1.name.localeCompare(h2.name)).map(ing=>{
              return <TableRow hover key={"item"+ing.id}
                onClick={(event) => handleClick(event, {...ing})}>
                <TableCell >{ing.measure_unit}</TableCell>
                <TableCell >{ing.name}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>);
};

export default IngredientsPage;
