import { SET_MEAL_PHOTOS_API_STATE, MEAL_PHOTO_SAVE, MEAL_PHOTO_DELETE, LOGOUT } from '../constants/ActionTypes'

const meal_photos = (state = {apiState:"init"}, action) => {
    switch (action.type) {
        case SET_MEAL_PHOTOS_API_STATE:
            return {...state,apiState:action.apiState}
        case MEAL_PHOTO_DELETE:
        case MEAL_PHOTO_SAVE:
            return {apiState:"ready"};
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
          }
    }

export default meal_photos;
