import * as types from '../constants/ActionTypes';
import IngredientService from "../services/ingredient.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_INGREDIENTS_API_STATE,
  apiState
});

const receiveIngredients = ingredients => ({
    type: types.RECEIVE_INGREDIENTS,
    ingredients
});

export const getIngredients = () => dispatch => {
  dispatch(setAPIState("get"));
  return IngredientService.getIngredients().then(
    response => {
      dispatch(receiveIngredients(response.data));
    },
    handleAPIError(dispatch));
};

const saveIngredientAction = ingredient => ({
    type: types.INGREDIENT_SAVE,
    ingredient
});

export const saveIngredient = ingredient => dispatch => {
  dispatch(setAPIState("save"));
  return IngredientService.saveIngredient(ingredient).then(
    response => {
      console.log(response);
      if (!ingredient.id){
        ingredient.id = response.data.id;
      }
      dispatch(saveIngredientAction(ingredient));
    },
    handleAPIError(dispatch));
}

const deleteIngredientAction = ingredientId => ({
    type: types.INGREDIENT_DELETE,
    ingredientId
});

export const deleteIngredient = ingredientId => dispatch => {
  dispatch(setAPIState("delete"));
  return IngredientService.deleteIngredient(ingredientId).then(
    response => {
    dispatch(deleteIngredientAction(ingredientId))
  },
  handleAPIError(dispatch));
}
