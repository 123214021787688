import * as types from '../constants/ActionTypes';
import MealTimeService from "../services/meal_time.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_MEAL_TIMES_API_STATE,
  apiState
});

const receiveMealTimes = mealTimes => ({
    type: types.RECEIVE_MEAL_TIMES,
    mealTimes
});

export const getMealTimes = () => dispatch => {
  dispatch(setAPIState("get"));
  return MealTimeService.getMealTimes().then(
    response => {
      dispatch(receiveMealTimes(response.data));
    },
    handleAPIError(dispatch));
};
