import React from 'react';
import { useSelector } from "react-redux";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import IngredientForm from '../ingredients/IngredientForm.component';

const filter = createFilterOptions();

const IngredientsList = (props) =>{
  const [newIngredient, setNewIngredient] = React.useState(null);
  const [ingredient, setIngredient] = React.useState({quantity:1, id:null});
  const ingredients = useSelector(state => state.ingredients);
  const {meal, onChange} = props;

  const delIngredient = ing => () => {
    onChange({...meal, ingredients: meal.ingredients.filter(i=>i.id != ing.id)});
  };

  const applyChanges = () => {
    const newSet = meal.ingredients.filter(i=>i.id != ingredient.prevId && i.id != ingredient.id);
    newSet.push(ingredient);
    onChange({...meal,
      ingredients: newSet
    });
    addIngredient();
  };

  const updateIngredient = ing => {
    setIngredient(ing);
  };

  const addIngredient = () => {
    setIngredient({quantity:1, id:null, prevId:0});
  };

  return (
    <>
      <IngredientForm ingredient={newIngredient} onChange={setNewIngredient}/>
      <List component="nav" aria-label="ingredients">
        {meal.ingredients.map(ing=>{
          return (
            <ListItem key={""+ing.id} >
              <ButtonGroup  variant="contained" size="small" >
                <Button style={{textAlign:"left"}} fullWidth={true} color="default"
                  onClick={()=>setIngredient({...ing, prevId:ing.id})} endIcon={<EditIcon/>}>
                  {""+ing.quantity} {ing.measure_unit} {ing.name}
                </Button>
                <Tooltip key="del-ingredient" title="Delete ingredient">
                  <IconButton aria-label="delete ingredient" onClick={delIngredient(ing)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            </ListItem>)
        })}
        <ListItem key={"add-li"} >
          <Button style={{textAlign:"left"}} fullWidth={true} color="default"
            onClick={addIngredient} endIcon={<AddIcon/>}>
            Add ingredient
          </Button>
        </ListItem>
      </List>
      {ingredient.prevId || ingredient.prevId===0?<>
      <FormControl>
        <TextField id="quantity" label="Quantity"
          value={ingredient.quantity} type="number"
          onChange={(event)=>updateIngredient({...ingredient, quantity:event.target.value})} />
      </FormControl>
      <Autocomplete
        value={ingredient.id}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setNewIngredient({name:newValue});
            });
          } else if (newValue && newValue.inputValue) {
            setNewIngredient({name:newValue.inputValue});
          } else {
            updateIngredient({...ingredient,
              id:newValue?newValue.id:null,
              name:newValue?newValue.name:null,
              measure_unit:newValue?newValue.measure_unit:null})
            //setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = params.inputValue?options.filter(i=>i.name.toLowerCase().includes(params.inputValue.toLowerCase())):options;

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Create "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        options={ingredients.records.sort((i1,i2)=>i1.name.localeCompare(i2.name))}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          const found = ingredients.records.find(i=>i.id===option);
          return found?found.measure_unit + " " + found.name:"";
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(option) => option.name}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Ingredient" variant="outlined" />
        )}
      />
      <Tooltip key="change-ingredient" title="Apply changes">
        <IconButton aria-label="change ingredient" onClick={applyChanges}>
          <DoneIcon />
        </IconButton>
      </Tooltip></>:null
      }
    </>
  )
};
export default IngredientsList;
