import { SET_INGREDIENTS_API_STATE, RECEIVE_INGREDIENTS, INGREDIENT_DELETE, INGREDIENT_SAVE, LOGOUT } from '../constants/ActionTypes'

const ingredients = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
      case SET_INGREDIENTS_API_STATE:
          return {...state,apiState:action.apiState}
      case RECEIVE_INGREDIENTS:
          return {records:action.ingredients, apiState:"ready"};
      case INGREDIENT_DELETE:
          return {records:state.records.filter(ingredient => ingredient.id !== action.ingredientId), apiState:"ready"};
      case INGREDIENT_SAVE:
          var found = false;
          var result = state.records.map(ingredient => {
              if (ingredient.id === action.ingredient.id) {
                  found = true;
                  return action.ingredient;
              }
              return ingredient;
          });
          if (!found){
              result = [...state.records, action.ingredient];
          }
          return {records:result, apiState:"ready"};
      case LOGOUT:
        return {records:[], apiState:"init"};
        default:
            return state;
    }
}
export default ingredients;
