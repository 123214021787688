import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const FeaturePicker = (props) =>{
  const mealFeatures = useSelector(state => state.mealFeatures);
  const {selectedFeatures, onChange} = props;

  const handleChangeMultiple = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <InputLabel id="feature-picker-label">Features</InputLabel>
      <Select
        labelId="feature-picker-label"
        id="feature-picker"
        multiple
        value={selectedFeatures}
        onChange={handleChangeMultiple}
        input={<Input id="feature-picker-chip" />}
        renderValue={(selected) => (
          <div >
            {selected.map((value) => (
              <Chip key={value}
              label={mealFeatures.records.filter(feature=>feature.id === value)[0].name}/>
            ))}
          </div>
        )}
      >
        {mealFeatures.records.map(feature => (
          <MenuItem key={feature.id} value={feature.id} >
            {feature.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default FeaturePicker;
