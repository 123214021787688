import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HtmlTooltip from '@material-ui/core/Tooltip';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import WarningIcon from '@material-ui/icons/Warning';
import CategoryIcon from '@material-ui/icons/Category';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import MealRating from '../common/MealRating.component';
import EditIcon from '@material-ui/icons/Edit';
import IconMap from '../../helpers/iconMap'

const PlannedMeal = (props) =>{
  const meals = useSelector(state => state.meal);
  const mealFeatures = useSelector(state => state.mealFeatures);
  const {plan, onClick, onEditMealClick, showRating, showEdit} = props;

  const showRecipe = meal => () => {
    if (meal.recipeURL.startsWith("http://") || meal.recipeURL.startsWith("https://")){
      window.open(meal.recipeURL, "_blank")
    }
  };

  const meal = meals.records.find(meal=>meal.id === plan.mealId);
  return (
    <>
    <ButtonGroup  variant="contained" size="small" >
      {meal?meal.meal_features.map(f=>{
        const ico = IconMap.faMappedIcon(mealFeatures.records.find(mf=>mf.id===f).name);
        return ico&&<IconButton key={''+meal.id+f}>{ico}</IconButton>
      }):null}
      <Button color="default" onClick={onClick}
      endIcon={
        meal && meal.advancedPrep ?
          <HtmlTooltip title={"Advanced prep required"}>
            <WarningIcon fontSize="small" style={{ color: '#ff3333' }} />
          </HtmlTooltip> : null
      }>{meal?(meal.name+(showEdit&&plan.portionsConsumed?` (X ${plan.portionsConsumed})`:'')):"Meal not found"}</Button>
      {meal && meal.recipeURL && meal.recipeURL.length > 0 ?
        (<Tooltip key="recipe-tip" title="View recipe">
          <IconButton aria-label="view recipe" onClick={showRecipe(meal)}>
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>) : null
      }
      {meal?[
        showRating?
        <Tooltip key="rating-tip" title="Rating">
          <IconButton aria-label="view recipe" onClick={showRecipe(meal)}>
            <MealRating value={meal.avgRating} singleIcon={true} />
          </IconButton>
        </Tooltip>:null,
        showEdit?
        <Tooltip key="edit-tip" title="Edit Meal">
          <IconButton aria-label="edit meal" onClick={onEditMealClick(meal)}>
            <EditIcon />
          </IconButton>
        </Tooltip>:null]:null}
    </ButtonGroup></>
  );
};

export default PlannedMeal;
