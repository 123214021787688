import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import renderDateLabel from '../../helpers/formatting';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import HolidayForm from './HolidayForm.component';

const HolidaysPage = () =>{
  const [holiday, setHoliday] = React.useState(null);
  const holidays = useSelector(state => state.holidays);

  const handleClick = (event, hol) => {
    setHoliday(hol);
  };

  return (<>
    <HolidayForm holiday={holiday} onChange={setHoliday}/>
    <Button color="primary" variant="contained"
      onClick={(event) => handleClick(event, {start_date:new Date(), end_date:new Date()})}>New Holiday</Button>
    <TableContainer component={Paper} style={{'maxHeight':'400px'}}>
      <Table size="small" stickyHeader >
          <TableHead>
              <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {holidays.records.sort((h1,h2)=>h1.end_date<h2.end_date).map(hol=>{
              return <TableRow hover key={"item"+hol.id}
                onClick={(event) => handleClick(event, {...hol})}>
                <TableCell >{hol.name}</TableCell>
                <TableCell >{renderDateLabel(hol.start_date)}</TableCell>
                <TableCell >{renderDateLabel(hol.end_date)}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>);
};

export default HolidaysPage;
