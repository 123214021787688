import * as types from '../constants/ActionTypes';
import MealPhotoService from "../services/meal_photo.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_MEAL_PHOTOS_API_STATE,
  apiState
});

const saveMealPhotoAction = photo => ({
    type: types.MEAL_PHOTO_SAVE,
    photo
});

export const saveMealPhoto = photo => dispatch => {
  dispatch(setAPIState("save"));
  return MealPhotoService.saveMealPhoto(photo).then(
    response => {
      console.log(response);
      if (!photo.id){
        photo.id = response.data.id;
        photo.filename = response.data.filename;
      }
      dispatch(saveMealPhotoAction(photo));
    },
    handleAPIError(dispatch));
}

const deleteMealPhotoAction = photo => ({
    type: types.MEAL_PHOTO_DELETE,
    photo
});

export const deleteMealPhoto = photo => dispatch => {
  dispatch(setAPIState("delete"));
  return MealPhotoService.deleteMealPhoto(photo.id).then(
    response => {
    dispatch(deleteMealPhotoAction(photo))
  },
  handleAPIError(dispatch));
}
