import { combineReducers } from 'redux';
import auth from "./auth";
import message from "./message";
import mealTimes from "./meal_time";
import meal from "./meal";
import plannedMeals from "./planned_meal";
import mealFeatures from "./meal_feature";
import holidays from "./holiday";
import ingredients from "./ingredient";
import meal_photos from "./meal_photo";
import plan_template from "./plan_template";

export default combineReducers({
    auth,
    message,
    mealTimes,
    meal,
    plannedMeals,
    mealFeatures,
    holidays,
    ingredients,
    meal_photos,
    plan_template
})
