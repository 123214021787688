import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PlannedMeal from './PlannedMeal.component';
import { addDays, formatISO, eachDayOfInterval, isSameDay, startOfDay, getDay } from 'date-fns'
import { getPlannedMeals, savePlannedMeal } from '../../actions/planned_meal.actions';
import { setSelectedMeal } from '../../actions/meal.actions';
import { setSelectedPlanTemplate} from '../../actions/plan_template.actions';
import PlanForm from './PlanForm.component';
import MealForm from '../common/MealForm.component';
import ShoppingList from './ShoppingList.component';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import renderDateLabel from '../../helpers/formatting';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '5px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const PlannerPage = () =>{
  const [fromDate, setFromDate] = React.useState(startOfDay(new Date()));
  const [toDate, setToDate] = React.useState(addDays(new Date(),7));
  const [showControls, setShowControls] = React.useState(false);
  const [refreshTimerStarted, setRefreshTimerStarted] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const [shoppingListOpen, setShoppingListOpen] = React.useState(false);
  const meals = useSelector(state => state.meal);
  const mealTimes = useSelector(state => state.mealTimes);
  const plannedMeals = useSelector(state => state.plannedMeals);
  const mealFeatures = useSelector(state => state.mealFeatures);
  const holidays = useSelector(state => state.holidays);
  const plan_templates = useSelector(state => state.plan_template);
  const isKiosk = useSelector(state => state.auth.kiosk);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (plannedMeals.apiState !== "get" && mealFeatures.apiState == "ready"){
      dispatch(getPlannedMeals(fromDate, toDate));
      if (isKiosk && !refreshTimerStarted){
        setDatesAndTimer();
        setRefreshTimerStarted(true);
      }
    }
  },[fromDate,toDate, mealFeatures.apiState]);

  const setDatesAndTimer = () => {
    setTimeout(()=>{
      setFromDate(startOfDay(new Date()));
      setToDate(addDays(new Date(),7));
      setDatesAndTimer();
    },600000);
  }

  const onDateChange = (event) =>{
    if (event.target.id == "fromDate"){
      setFromDate(event.target.valueAsDate);
    } else {
      setToDate(event.target.valueAsDate);
    }
  };

  const showPlanForm = (plan) => () =>{
    setSelectedPlan({...plan});
  };

  const closeDialog = () => {
    setSelectedPlan(null);
  }

  const onPlanChange = (changedPlan) => {
    setSelectedPlan({...changedPlan});
  };

  const newPlan = (date, time) => () => {
    setSelectedPlan({date, mealTimeId:time});
  };

  const selectedMealChanged = (meal) => {
    dispatch(setSelectedMeal(meal));
  };

  const onEditMealClick = meal => () => {
    dispatch(setSelectedMeal({...meal}));
  };

  const applyTemplate = date => () => {
    const temp = plan_templates.selectedRow;
    //const diff = ;
    temp.plan_template_entries.forEach(e=>{
      let daysToAdd = ((1+temp.firstWeekDay+e.dayOffset)%7)-getDay(date);
      if (daysToAdd < 0){
        daysToAdd = daysToAdd + 7;
      }
      dispatch(savePlannedMeal({
        date:addDays(date,daysToAdd),
        mealId:e.mealId,
        mealTimeId:e.mealTimeId
      }))
    });
  };

  const pickSelectedTemplate = ev => {
    const found = plan_templates.records.find(row=>row.id===ev.target.value);
    updateSelectedTemplate(found?{...found}:null);
  };

  const updateSelectedTemplate = newObj => {
    dispatch(setSelectedPlanTemplate(newObj));
  };

  mealTimes.records.forEach(time=>{
    time.isUsed = plannedMeals.records.find(plan=>plan.mealTimeId===time.id);
  });

  const renderMeal = id => {
    if (meals && meals.records){
      const find = meals.records.filter(meal=>meal.id === id);
      if (find.length > 0){
        return find[0].name;
      }
    }
    return `Meal ${id} not found`;
  };

  return (
    <>
      <MealForm meal={meals.selectedRow} onChange={selectedMealChanged} />
      <PlanForm plan={selectedPlan} onClose={closeDialog}
        onChange={onPlanChange}
        onEditMealClick={onEditMealClick}/>
      <ShoppingList open={shoppingListOpen} onClose={()=>setShoppingListOpen(false)}
        fromDate toDate />
      {!isKiosk?
      (<form className={classes.container} noValidate>
        <TextField
          id="fromDate"
          label="From"
          type="date"
          value={formatISO(fromDate, { representation: 'date' })}
          className={classes.textField}
          onChange={onDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="toDate"
          label="To"
          type="date"
          defaultValue={formatISO(toDate, { representation: 'date' })}
          className={classes.textField}
          onChange={onDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel control={
          <Switch id="advancedPrep" checked={showControls}
            onChange={(event)=>setShowControls(!showControls)} />
          } label="Edit" />
      {showControls&&<>
      <Button aria-label="new meal" onClick={()=>selectedMealChanged(
        {meal_photos:[],meal_features:[],meal_ratings:[],ingredients:[]})}
        endIcon={<AddIcon />} size="small"
        variant="contained" >New Meal</Button>
          <FormControl style={{minWidth:150,marginLeft:5}}>
            <InputLabel id="template-plan-label">Plan Template</InputLabel>
            <Select
              labelId="template-plan-label"
              id="template-plan"
              value={plan_templates.selectedRow?plan_templates.selectedRow.id:''}
              onChange={pickSelectedTemplate}
            >
            {plan_templates.records.map(row=>{
              return (<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>);
            })}
            </Select>
          </FormControl>
        </>}
        <IconButton aria-label="show shopping list"
          onClick={()=>setShoppingListOpen(true)}>
          <ShoppingCartIcon />
        </IconButton>
      </form>):null}

      <TableContainer component={Paper}>
        <Table size="small" stickyHeader >
            <TableHead>
                <TableRow>
                    <TableCell padding="none" align="center">Date</TableCell>
                    {mealTimes.records.map(time=>{
                      return (
                        (showControls || time.isUsed) &&
                        <TableCell key={'time-header-'+time.id}
                          padding="none">{time.name}</TableCell>
                      );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
            {eachDayOfInterval({ start: fromDate, end: toDate })
            .map((loop)=>{
              const holiday = holidays.records.find(hol=>hol.start_date <= loop && loop <= hol.end_date)
              return (
                <TableRow hover key={"item"+loop}>
                  <TableCell padding="none">
                    {renderDateLabel(loop)}
                    {showControls&&<IconButton aria-label="add plan template"
                      onClick={applyTemplate(loop)}
                      disabled={plan_templates.selectedRow?false:true}>
                      <PlaylistAddIcon />
                    </IconButton>}
                  </TableCell>
                  {holiday?
                    (<TableCell key={loop} colSpan={mealTimes.records.length}
                      align="center">
                      {holiday.name}
                    </TableCell>)
                  :
                    mealTimes.records.map(time=>{
                      return (
                        (showControls || time.isUsed) &&
                        <TableCell key={loop+'-'+time.id} padding="none">
                          {plannedMeals.records
                            .filter(plan=>isSameDay(plan.date, loop) && plan.mealTimeId === time.id)
                            .map(plan=>{
                              return (<PlannedMeal key={"pm"+plan.id} plan={plan}
                                onClick={showPlanForm(plan)}
                                onEditMealClick={onEditMealClick}
                                showEdit={showControls} />);
                            })
                          }
                          {showControls?
                          <IconButton aria-label="add plan" onClick={newPlan(loop, time.id)}>
                            <AddIcon />
                          </IconButton>:null}
                        </TableCell>
                      )
                    })}
                </TableRow>
              );
            })}
            </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PlannerPage;
