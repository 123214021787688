import { useDispatch, useSelector } from "react-redux";
import MealSearch from '../planner/MealSearch.component';
import MealForm from '../common/MealForm.component';
import { setSelectedMeal } from '../../actions/meal.actions';

const MenuPage = (props) =>{
  const meals = useSelector(state => state.meal);
  const dispatch = useDispatch();

  const selectedMealChanged = (meal) => {
    dispatch(setSelectedMeal(meal));
  };
  const onEditMealClick = meal => () => {
    dispatch(setSelectedMeal({...meal}));
  };

  const meal = meals.records[0];
  return (<>
    <MealForm meal={meals.selectedRow} onChange={selectedMealChanged} />
    <MealSearch selected={null} pickMeal={null}
      onEditMealClick={onEditMealClick}
      gallery={true}/>
  </>)
}

export default MenuPage;
