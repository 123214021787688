import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import { savePlanTemplate, deletePlanTemplate , setSelectedPlanTemplate} from '../../actions/plan_template.actions';
import { setSelectedMeal } from '../../actions/meal.actions';
import PlannedMeal from '../planner/PlannedMeal.component';
import MealForm from '../common/MealForm.component';
import PlanForm from '../planner/PlanForm.component';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '5px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const TemplatesAdminPage = (props) => {
  const [selectedPlan, setSelectedPlan] = React.useState(null);
  const plan_templates = useSelector(state => state.plan_template);
  const mealTimes = useSelector(state => state.mealTimes);
  const meals = useSelector(state => state.meal);
  const dispatch = useDispatch();
  const classes = useStyles();

  const selectedMealChanged = (meal) => {
    dispatch(setSelectedMeal(meal));
  };

  const onEditMealClick = meal => () => {
    dispatch(setSelectedMeal({...meal}));
  };

  const pickSelected = ev => {
    updateSelected({...plan_templates.records.find(row=>row.id===ev.target.value)});
  };

  const updateSelected = newObj => {
    dispatch(setSelectedPlanTemplate(newObj));
  };

  const saveSelected = () => {
    dispatch(savePlanTemplate(plan_templates.selectedRow));
  };

  const deleteSelected = () => {
    dispatch(deletePlanTemplate(plan_templates.selectedRow.id));
  };

  const showPlanForm = (newRow) => () => {
    setSelectedPlan(newRow);
  };

  const getMealTimeById = id => {
    return mealTimes.records.find(t=>t.id===id);
  };

  const selected = plan_templates.selectedRow;
  const days = [[],[],[],[],[],[],[]].map(day=>mealTimes.records.map(t=>[]));
  if (selected && selected.plan_template_entries){
    selected.plan_template_entries.forEach(e=>{
      let daySlot = days[e.dayOffset];
      if (!daySlot){
        daySlot = days[e.dayOffset] = [];
      }
      e.mealTime = getMealTimeById(e.mealTimeId);
      e.meal = meals.records.find(m=>m.id===e.mealId);
      let timeSlot = daySlot[e.mealTime.order-1];
      if (!timeSlot){
        timeSlot = daySlot[e.mealTime.order-1] = [];
      }
      timeSlot.push(e);
    })
  }
  return (<>
    <MealForm meal={meals.selectedRow} onChange={selectedMealChanged} />
    <PlanForm plan={selectedPlan} onClose={()=>setSelectedPlan(null)}
      onChange={p=>setSelectedPlan(p)}
      onEditMealClick={onEditMealClick}/>
    <form className={classes.container} noValidate>
      <FormControl style={{minWidth:150}}>
        <InputLabel id="mealtime-label">Plan Template</InputLabel>
        <Select
          labelId="mealtime-label"
          id="mealtime"
          value={selected?selected.id:0}
          onChange={pickSelected}
        >
        <MenuItem value={0}>New</MenuItem>
        {plan_templates.records.map(row=>{
          return (<MenuItem value={row.id}>{row.name}</MenuItem>);
        })}
        </Select>
      </FormControl>
      <FormControl style={{minWidth:150}}>
        <TextField id="name" label="Name" value={selected?selected.name:""}
          onChange={ev=>{updateSelected({...selected,name:ev.target.value})}} />
      </FormControl>
      <FormControl style={{minWidth:150}}>
        <InputLabel id="firstWeekDay-label">First Week Day</InputLabel>
        <Select
          labelId="firstWeekDay-label"
          id="firstWeekDay"
          value={selected?selected.firstWeekDay:0}
          onChange={event=>updateSelected({...selected, firstWeekDay:event.target.value})}
        >
        {weekDays.map((day,i)=>{
            return <MenuItem key={i} value={i}>{day}</MenuItem>
          })}
        </Select>
      </FormControl>
      <ButtonGroup  variant="contained" size="small" >
        <Button aria-label="save" color="primary" onClick={saveSelected}
          endIcon={<SaveIcon />}>Save</Button>
        <Button aria-label="delete" color="secondary" onClick={deleteSelected}
          endIcon={<DeleteIcon />}>Delete</Button>
      </ButtonGroup>
    </form>
    {selected&&
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader >
            <TableHead>
                <TableRow>
                    <TableCell padding="none" align="center">Day</TableCell>
                    {mealTimes.records.map(time=>{
                      return (
                        <TableCell key={'time-header-'+time.id}
                          padding="none">{time.name}</TableCell>
                      );
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
        {days.map((day,di)=>{
          return <TableRow hover key={"item"+di}>
            <TableCell padding="none">
              {weekDays[(di+selected.firstWeekDay+7)%7]}
            </TableCell>
            {day.map((time,ti)=>{
              return (
            <TableCell key={di+'-'+ti} padding="none">
              {time.map(e=>{
                return (<PlannedMeal key={"pm"+e.id} plan={e}
                  onClick={showPlanForm(e)}
                  onEditMealClick={onEditMealClick}
                  showEdit={true} />);})}
              <IconButton aria-label="add plan"
                onClick={showPlanForm({
                  dayOffset:di,
                  mealTimeId:mealTimes.records[ti].id,
                  planTemplateId:selected.id
                })}>
                <AddIcon />
              </IconButton>
            </TableCell>)
          })}</TableRow>
        })}</TableBody>
      </Table>
    </TableContainer>}
  </>)
};

export default TemplatesAdminPage;
