import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'plan_template_entry/';

class PlanTemplateEntryService {

  savePlanTemplateEntry(row) {
    if (row.id){
      return axios.put(API_URL+row.id, row, { headers: authHeader() });
    } else {
      return axios.post(API_URL, row, { headers: authHeader() });
    }
  }

  deletePlanTemplateEntry(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new PlanTemplateEntryService();
