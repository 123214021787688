import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEgg, faFish, faDrumstickBite, faBacon, faHamburger, faMountain,
  faAppleAlt, faCarrot, faPepperHot, faCheese } from '@fortawesome/free-solid-svg-icons'

const iconMap = name => {
    switch (name){
    case "Eggs": return {ico:faEgg, color:'#dfcb74'};
    case "Fish": return {ico:faFish, color:'#28709c'};
    case "Chicken": return {ico:faDrumstickBite, color:'#886a00'};
    case "Poultry": return {ico:faDrumstickBite, color:'#886a00'};
    case "Pork": return {ico:faBacon, color:'#915f7f'};
    case "Beef": return {ico:faHamburger, color:'#a25649'};
    case "Lamb": return {ico:faMountain, color:'#502416'};
    case "Fruit": return {ico:faAppleAlt, color:'#239918'};
    case "Vegetables": return {ico:faCarrot, color:'#c98613'};
    case "Chilli Hot": return {ico:faPepperHot, color:'#c63219'};
    case "Cheese": return {ico:faCheese, color:'#e6ce6a'};
    default: return null;
  }
}
const faMappedIcon = name => {
  const mapped = iconMap(name);
  return mapped?<FontAwesomeIcon key={name} icon={mapped.ico} style={{color:mapped.color,fontSize:'1.8em'}}/>:null;
}
export default {iconMap, faMappedIcon};
