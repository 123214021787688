import { SET_HOLIDAYS_API_STATE, RECEIVE_HOLIDAYS, HOLIDAY_SAVE, HOLIDAY_DELETE, LOGOUT } from '../constants/ActionTypes'

const holidays = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_HOLIDAYS_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_HOLIDAYS:
            return {records:action.holidays.map(holiday=>{
              return {
                ...holiday,
                start_date: new Date(holiday.start_date),
                end_date: new Date(holiday.end_date)
              }}), apiState:"ready"};
        case HOLIDAY_DELETE:
            return {records:state.records.filter(holiday => holiday.id !== action.holidayId), apiState:"ready"};
        case HOLIDAY_SAVE:
            var found = false;
            var result = state.records.map(holiday => {
                if (holiday.id === action.holiday.id) {
                    found = true;
                    return action.holiday;
                }
                return holiday;
            });
            if (!found){
                result = [...state.records, action.holiday];
            }
            return {records:result, apiState:"ready"};
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
          }
    }

export default holidays;
