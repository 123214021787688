import * as types from '../constants/ActionTypes';
import HolidayService from "../services/holiday.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_HOLIDAYS_API_STATE,
  apiState
});

const receiveHolidays = holidays => ({
    type: types.RECEIVE_HOLIDAYS,
    holidays
});

export const getHolidays = (from, to) => dispatch => {
  dispatch(setAPIState("get"));
  return HolidayService.getHolidays(from, to).then(
    response => {
      dispatch(receiveHolidays(response.data));
    },
    handleAPIError(dispatch));
};

const saveHolidayAction = holiday => ({
    type: types.HOLIDAY_SAVE,
    holiday
});

export const saveHoliday = holiday => dispatch => {
  dispatch(setAPIState("save"));
  return HolidayService.saveHoliday(holiday).then(
    response => {
      console.log(response);
      if (!holiday.id){
        holiday.id = response.data.id;
      }
      dispatch(saveHolidayAction(holiday));
    },
    handleAPIError(dispatch));
}

const deleteHolidayAction = holidayId => ({
    type: types.HOLIDAY_DELETE,
    holidayId
});

export const deleteHoliday = holidayId => dispatch => {
  dispatch(setAPIState("delete"));
  return HolidayService.deleteHoliday(holidayId).then(
    response => {
    dispatch(deleteHolidayAction(holidayId))
  },
  handleAPIError(dispatch));
}
