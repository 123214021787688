import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'meal_feature/';

class MealFeatureService {
  getMealFeatures() {
    return axios.get(API_URL, { headers: authHeader() });
  }
}

export default new MealFeatureService();
