import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'meal/';

class MealService {

  getMeals() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  saveMeal(meal) {
    if (meal.id){
      return axios.put(API_URL+meal.id, meal, { headers: authHeader() });
    } else {
      return axios.post(API_URL, meal, { headers: authHeader() });
    }
  }

  deleteMeal(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }

  tagMeal(meal, featureId){
    return axios.put(API_URL+"tag/"+meal.id, {mealId:meal.id, featureId}, { headers: authHeader() });
  }

  untagMeal(meal, featureId){
    return axios.put(API_URL+"untag/"+meal.id, {mealId:meal.id, featureId}, { headers: authHeader() });
  }
}

export default new MealService();
