import { useDispatch } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DatePicker } from '@material-ui/pickers';
import { saveHoliday, deleteHoliday } from '../../actions/holiday.actions';
import TextField from '@material-ui/core/TextField';

const HolidayForm = (props) =>{
  const {holiday, onChange} = props;
  const dispatch = useDispatch();

  const deleteHol = () => {
    dispatch(deleteHoliday(holiday.id));
    close();
  };
  const saveHol = () => {
    dispatch(saveHoliday(holiday));
    close();
  };

  const handleDateChange = (date) => {
    onChange({...holiday, date});
  };
  const close = () => {
    onChange(null)
  }

  if (!holiday){
    return null;
  }
  return (
    <>
    <Dialog
        title="Holiday"
        open={holiday != null}
        scroll="paper"
        maxWidth="xl"
        onClose={close}
        >
      <DialogTitle id="form-dialog-title">Holiday</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container >
          <Grid item>
            <FormControl fullWidth={true}>
              <TextField id="name" label="Name" value={holiday.name}
                onChange={(event)=>onChange({...holiday, name:event.target.value})} />
            </FormControl>
            <DatePicker autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              value={holiday.start_date}
              onChange={(date)=>onChange({...holiday, start_date:date})} />
            <DatePicker autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              value={holiday.end_date}
              onChange={(date)=>onChange({...holiday, end_date:date})} />
            </Grid>
          </Grid>
        </DialogContent>
      <DialogActions>
        <ButtonGroup  variant="contained" size="small" >
          <Button aria-label="save" color="primary" onClick={saveHol}
            endIcon={<SaveIcon />}>Save</Button>
          <Button aria-label="delete" color="secondary" onClick={deleteHol}
            endIcon={<DeleteIcon />}>Delete</Button>
          <Button aria-label="cancel" onClick={close}
            endIcon={<CancelIcon />}>Cancel</Button>
        </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HolidayForm;
