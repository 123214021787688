import * as types from '../constants/ActionTypes';
import MealFeatureService from "../services/meal_feature.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_MEAL_FEATURES_API_STATE,
  apiState
});

const receiveMealFeatures = mealFeatures => ({
    type: types.RECEIVE_MEAL_FEATURES,
    mealFeatures
});

export const getMealFeatures = () => dispatch => {
  dispatch(setAPIState("get"));
  return MealFeatureService.getMealFeatures().then(
    response => {
      dispatch(receiveMealFeatures(response.data));
    },
    handleAPIError(dispatch));
};
